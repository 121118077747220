console.log('polyfill.js: start');
require('babel-register')({
    // NOTE: If `dynamic-import-node` is in .babelrc alongside
    // `syntax-dynamic-import` it breaks webpack's bundle
    // splitting capability. Only load during runtime on the node-side
    plugins: ['dynamic-import-node']
});
import 'core-js/stable';
import 'regenerator-runtime/runtime';

console.log('polyfill.js: end');
